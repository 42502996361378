import React, { ReactNode, useEffect, useState } from 'react';
import { motion, useAnimation, AnimationControls } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface AnimatedContainerProps {
  children: ReactNode;
  delay?: number;
}

const AnimatedContainer: React.FC<AnimatedContainerProps> = ({ children,delay }) => {
  const controls: AnimationControls = useAnimation();
  const [hasAnimated, setHasAnimated] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.5, // Adjust the threshold as needed
    triggerOnce: true, // Ensures the animation triggers only once
  });

  useEffect(() => {
    if (inView && !hasAnimated) {
     setTimeout(()=>{
      controls.start({
        opacity: 1,
        scale: 1,
        rotateX: 0,
        rotateY: 0,
        transition: { duration: 0.3},
      });
     },delay??0)
    
      setHasAnimated(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasAnimated, controls]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial={{ opacity:0, scale: 0.95}}
      style={{ perspective: 1000,zIndex:10000 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedContainer;
