import React, { ReactNode, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Nav from "./nav";
import Main from "./main";
import Header from "./header";
import { useResponsive } from "../../hooks/use-responsive";
import MouseInteraction from "../../common-ui/MouseInterection";
import { Container } from "@mui/material";
export type PaletteMode = "light" | "dark";

interface DashboardLayoutProps {
  children: ReactNode;
  mode: PaletteMode;
  toggleColorMode: () => void;
  HeaderComponent?: React.JSX.Element;
}

export default function DashboardLayout({
  children,
  mode,
  toggleColorMode,
  HeaderComponent,
}: DashboardLayoutProps) {
  const [openNav, setOpenNav] = useState<boolean>(false);
  const lgUp = useResponsive("up", "lg");
  return (
    <Container maxWidth='lg'>
      {!lgUp && (
        <Header
          mode={mode}
          HeaderComponent={HeaderComponent}
          toggleColorMode={toggleColorMode}
          onOpenNav={() => setOpenNav(true)}
        />
      )}
      {lgUp && <MouseInteraction />}
      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
        }}
      >
        <Nav
          toggleColorMode={toggleColorMode}
          openNav={openNav}
          onCloseNav={() => setOpenNav(false)}
          mode={mode}
        />
        <Main>{children}</Main>
      </Box>
    </Container>
  );
}

