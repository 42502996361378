
import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useResponsive } from "../hooks/use-responsive";
import { isLargeScreen } from "../utils/types";


interface props {

    icon: React.ReactElement,
    label?: string
    key?: string | number
    link: string
}
export const HoverIcon: React.FC<props> = ({ icon, link, label, key }) => {
    const [active, set_action] = useState<boolean>(false)
    const handleMouseEnter = () => {

        set_action(true)
    }
    const handleMouseLeave = () => {
        set_action(false)
    }


    const upLg = useResponsive('up', 'lg');

    return (
        <Stack direction="row" alignItems="center" justifyContent="start"
            sx={{ width: "100%" }} gap={1} key={key} className="left-icon-space mouse_interaction-target">
            <a
                target="_blank"
                rel="noreferrer"
                href={link}

                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Box
                    sx={(theme) => ({
                        color: theme.palette.text.primary,
                    })}>
                    {icon}
                </Box>


            </a>
            {
                active &&
                <Typography variant="subtitle1" className="label-land" sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                       display:'none'
                      },
                    position: "absolute",
                    marginLeft: isLargeScreen ? '50px': "30px",
                    fontWeight: "bold",
                    color: theme.palette.mode === "dark"
                        ? "#04cc9b"
                        : theme.palette.grey[800],
                    display: upLg ? 'flex' : 'none',
                    backgroundColor: theme.palette.mode === "dark"
                        ? "rgb(5, 175, 133,0.3)"
                        : theme.palette.grey[100],
                    padding: '5px',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '10px',
                       boxShadow:  theme.palette.mode === "dark"? '': 'rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px'
                })
                } id="cursor-follow">{label}</Typography>
            }
        </Stack>

    )
}