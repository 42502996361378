import { Stack, Theme, Grid } from "@mui/material";
import { projectsList } from "../../data/me";
import "./project.css";

import AnimatedContainer from "../../common-ui/GrowContainer";
import { ProjectProps } from "../../utils/types";
import { ProjectCard } from "../../common-ui/ProjectCard";
import { Text } from "../../component/Text";
const Projects = () => {
  return (
    <Stack id="works" direction="column" gap={4} sx={styles.layout}>
      <Text
        sx={styles.header}
        fontWeight={700}
        fontSize={25}
        id="projectheader"
        className="projec_intro targets-container"
      >
        Works /.
      </Text>

      <Grid container columnSpacing={2} rowSpacing={5}>
        {projectsList &&
          projectsList.map((data: ProjectProps, index: number) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                <AnimatedContainer>
                  <ProjectCard data={data} />
                </AnimatedContainer>
              </Grid>
            );
          })}
      </Grid>
    </Stack>
  );
};

export default Projects;

const styles = {
  layout: {
    margin: "60px 0px"
  },
  contents: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left"
  },
  header: (theme: Theme) => ({
    color:
      theme.palette.mode === "dark"
        ? "rgb(5, 175, 133)"
        : theme.palette.grey[900],

    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "530px"
    }
  }),

  cardContainer: () => ({
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "blue"
  })
};
