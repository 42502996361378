import { Box, Grid, Stack, Theme, Typography } from "@mui/material";
import React from "react";
import { SlCalender } from "react-icons/sl";
import { cardxProps } from "../../utils/types";
import { useResponsive } from "../../hooks/use-responsive";

import AnimatedContainer from "../../common-ui/GrowContainer";
import { Text } from "../../component/Text";

export const CardExperience: React.FC<cardxProps> = ({
  className,
  title,
  session,
  logo,
  detail,
  titleVariant = "h6",
  dateVariant = "subtitle2",
  detailVariant = "subtitle1",
  skills,
  index,
}) => {
  const upLg = useResponsive("up", "lg");

  return (
    <Stack
      gap={1}
      className={`cardx-container ${className && className} random_id`}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <AnimatedContainer delay={200}>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ marginTop: "-10px" }}
        >
          <Box
            sx={(theme) => ({
              marginLeft: upLg ? "-48px" : "-9px",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? index === 0
                    ? theme.palette.grey[500]
                    : theme.palette.grey[900]
                  : index === 0
                  ? theme.palette.grey[600]
                  : theme.palette.grey[50],
              borderColor:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[500]
                  : theme.palette.grey[600],
            })}
            className="cardx-point"
          ></Box>

          <img style={{ width: "25px", height: "25px" }} src={logo} alt="" />

          <Text
            sx={(theme) => ({
              marginLeft: upLg ? "7px" : "0px",
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[900],
              fontWeight: "bold",
            })}
            className="card-details"
          >
            {title}
          </Text>
        </Stack>
        </AnimatedContainer>
    

        {upLg && (
          <AnimatedContainer delay={300}>
     <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={(theme) => ({
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.grey[600]
                  : theme.palette.grey[500],
            })}
          >
            <SlCalender size={13} />

            <Typography
              className="card-details"
              sx={(theme) => ({
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[600]
                    : theme.palette.grey[500],
                fontWeight: "bold",
              })}
              variant={dateVariant}
            >
              {session}
            </Typography>
          </Stack>
          </AnimatedContainer>
     
        )}
      </Stack>
      <Stack
        sx={{
          marginLeft: "15px",
        }}
      >
    
     <Text sx={styles.detail}>{detail}</Text>
      
        <Grid
          container
          spacing={1}
          sx={{ width:"100%", marginTop: "20px" }}
        >
          {skills &&
            skills.map((data: skillProps, index: number) => {
              const delay = 200 + index * 80; 
              return (
                <Grid item xs={3} sm={2} md={1} key={index}>
               <AnimatedContainer delay={delay}>
               <Box
                    sx={(theme) => ({
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? "rgb(5, 175, 133,0.2)"
                          : theme.palette.grey[100],
                      boxShadow:
                        theme.palette.mode === "dark"
                          ? ""
                          : "rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px",
                    })}
                    className="skill-box"
                  >
                    <Text
                      variant="subtitle1"
                      sx={(theme) => ({
                        fontSize: upLg ? "13px" : "11px",
                        color:
                          theme.palette.mode === "dark"
                            ? "#04cc9b"
                            : theme.palette.grey[800],
                      })}
                      className="skill-box-text"
                    >
                      {data.name}
                    </Text>
                  </Box>
               </AnimatedContainer>
                 
                </Grid>
              );
            })}
        </Grid>
        <Stack direction="row" justifyContent="right">
          {!upLg && (
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={(theme) => ({
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[600]
                    : theme.palette.grey[500],
              })}
            >
              <SlCalender size={13} />
              <Typography
                className="card-details"
                sx={(theme) => ({
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey[600]
                      : theme.palette.grey[500],
                  fontWeight: "bold",
                })}
                variant={dateVariant}
              >
                {session}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

type skillType = "language" | "devtools" | "frameworks" | "os";
interface skillProps {
  type: skillType;
  name: string;
  image?: string;
  dark?: string;
  light?: string;
}

const styles = {
  detail: (theme: Theme) => ({
    color:
      theme.palette.mode === "dark"
        ? theme.palette.grey[300]
        : theme.palette.grey["900"],
    fontSize: 14,
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: 12
    }
  }),
};
