import { Box } from "@mui/material";

export const ProjectImage = ({
  image,
  link
}: {
  image?: string;
  link?: string;
}) => {
  console.log({link})
  
  return (
    <a
      href={link}
      target="_blank"
      className="mouse_interaction-target"
      rel="noopener noreferrer"
    >
      <Box sx={styles.container} className="scale-image-parent">
        <img
          className="scale-image"
          style={styles.image}
          src={image}
          alt=""
        ></img>
      </Box>
    </a>
  );
};

const styles = {
  container: {
    overflow: "hidden",
    borderRadius: "1px",
    boxShadow:
      "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"
  },
  image: {
    width: "100%"
  }
};
