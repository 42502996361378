import './App.css';
import  { useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import getLPTheme from './styles/theme/getLPTheme';
import "./styles/theme/styles.css"
import Router from './routes/sections';
type PaletteMode = 'light' | 'dark';

export default function App() {
  const [mode, setMode] = useState<PaletteMode>('dark');

  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };



  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />

      <Router mode={mode} toggleColorMode={toggleColorMode} />
    </ThemeProvider>

  );
}
