import { Box, Grid, Stack, Theme } from "@mui/material";
import React from "react";
import { BsBoxArrowUpRight, BsGithub } from "react-icons/bs";
import { ProjectProps } from "../utils/types";
import { Player } from "../models/Player";
import AnimatedContainer from "./GrowContainer";
import { ProjectImage } from "../component/ProjectImage";
import { Text } from "../component/Text";

export const ProjectCard: React.FC<{ data: ProjectProps }> = ({ data }) => {
  const { title, detail, link, github, image, tools, headerIcon, isVideo } =
    data;

    const projectLink=link ? link :github? github :undefined;
  return (
    <Box sx={styles.container}>
      {isVideo ? <Player path={image!} link={projectLink} /> : <ProjectImage link={projectLink}  image={image} />}

      <Stack direction="column" gap={2} sx={styles.content}>
        <AnimatedContainer delay={200}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={2}
          >
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Text sx={styles.title} fontWeight={"700"}>
                {title}
              </Text>
              {headerIcon}
            </Stack>
            <Stack spacing={2} direction="row" >
              {link && (
                <Box sx={styles.icon}>
                  <a
                    href={link}
                    target="_blank"
                    className="mouse_interaction-target"
                    rel="noopener noreferrer"
                    title={`Explore more about ${title}- ${link}`}
                  >
                    <BsBoxArrowUpRight size={15}/>
                  </a>
                </Box>
              )}

              {github && github && (
                <Box sx={styles.icon}>
                  <a
                    href={github}
                    target="_blank"
                    className="mouse_interaction-target"
                    rel="noopener noreferrer"
                    title={`Check out ${title}'s codebase on GitHub - ${github}`}
                  >
                    <BsGithub size={15}/>
                  </a>
                </Box>
              )}
            </Stack>
          </Stack>
        </AnimatedContainer>
        <AnimatedContainer delay={250}>
          <Text sx={styles.detail}>{detail}</Text>
        </AnimatedContainer>

        <AnimatedContainer delay={300}>
          <Stack direction="column" gap={1}>
            <Grid container direction="row" spacing={1}>
              {tools &&
                tools.map((tool, index) => (
                  <Grid item key={index} xs={4} sm={4} md={4} lg={3} xl={3}>
                    <Text sx={styles.tool} fontWeight={450} fontSize={13}>
                      {tool}
                    </Text>
                  </Grid>
                ))}
            </Grid>
          </Stack>
        </AnimatedContainer>
      </Stack>
    </Box>
  );
};

const styles = {
  container: (theme: Theme) => ({
    [theme.breakpoints.up("xl")]: {
      width: 500,
      marginBottom: "15px"
    },

    [theme.breakpoints.down("xl")]: {
      width: 430
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  }),
  
  title: (theme: Theme) => ({
    fontSize: 20,
    [theme.breakpoints.down("xl")]: {
      fontSize: 18
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 15
    }
  }),
  detail: (theme: Theme) => ({
    color:
      theme.palette.mode === "dark"
        ? theme.palette.grey[300]
        : theme.palette.grey["900"],
    fontSize: "14px",
    fontWeight: "500"
  }),
  image: {
    height: "160px",
    borderRadius: "10px",
    width: "95%",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
  },

  content:{
    width: "100%",
    overflow: "hidden"
  },
  contentLayout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 1
  },
  tool: (theme: Theme) => ({
    color: theme.palette.mode === "dark" ? "#13bd93" : theme.palette.grey["700"]
  }),
  icon: (theme: Theme) => ({
    color: theme.palette.mode === "dark" ? "#13bd93" : theme.palette.grey["700"],
    display:'flex',
    justifyContent:'right',
    alignItems:'center'
  })
};
