import { SxProps, Theme, TypographyProps, } from "@mui/material";
import  { ReactNode } from "react";


export const WINDOW_WIDTH=window.innerWidth
export const isLargeScreen=WINDOW_WIDTH > 1400

type variantType="h1" |"h2" |"h3" |"h4" |"h5" |"h6" | "subtitle1" |"subtitle2" 
export interface NavItemProps {
    title: string;
    handleClick: ()=>void;
    label ?:string
    
    
  }
  

  export interface LabelProps extends TypographyProps{

    className?:string,
    styles?:React.CSSProperties,
    children?: ReactNode;
    variant?:variantType
    sx?:  SxProps<Theme>
    id?:string 


  }

  type skillType="language" | "devtools" | "frameworks" | "os"
  interface skillProps{
    type:skillType,
    name:string,image?:string,  dark?:string,
    light?:string
}
  export interface cardxProps{
    index?:number
    className?:string,
    styles?:React.CSSProperties,
    children?: ReactNode;
    variant?:variantType
    sx?:  SxProps<Theme>
    id?:string ;
    title:string;
    session:string;
    detail:string;
    titleXs?: SxProps<Theme>
   dateXs?: SxProps<Theme>
    detailXs?: SxProps<Theme>
    detailVariant?:variantType;
    dateVariant?:variantType;
    titleVariant?:variantType;
    skills?:skillProps[],
    logo?:string,
   

  }





  export interface ProjectProps {
    title: string;
    detail: string;
    github?: string;
    link?: string;
    tools?: string[]
    image?: string
    headerIcon?:React.ReactElement;
    isVideo?: boolean;
}
