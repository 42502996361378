import { Stack, Box, Theme } from "@mui/material";
import { experienceData } from "../../data/me";
import { CardExperience } from "./card";
import "./experience.css";

import { useResponsive } from "../../hooks/use-responsive";
import { MobileProfile } from "../mobile-profile";
import { Text } from "../../component/Text";
import { grey } from "../../styles/theme/palette";

const Experience = () => {
  const upLg = useResponsive("up", "lg");

  return (
    <Stack id="experience" direction="column">
      {!upLg && <MobileProfile />}

      <Stack
        sx={{ marginTop: "40px" }}
        className="experience_h"
        direction="row"
        alignItems="center"
        justifyContent={upLg ? "left" : "space-between"}
      >
        <Text
          sx={styles.header}
          fontWeight={700}
          fontSize={25}
          id="projectheader"
          className="projec_intro targets-container"
        >
          Experience /.
        </Text>
        {upLg ? (
          <></>
        ) : (
          <Box className="expe-right-box" sx={styles.rightBox}></Box>
        )}
      </Stack>

      <Box sx={styles.experienceContainer}>
        {experienceData &&
          experienceData.map((data, index) => {
            return (
              <Stack
                key={index}
                gap={4}
                direction="column"
                justifyContent="space-evenly"
              >
                <CardExperience
                  title={data.title && data.title.text}
                  session={data.date}
                  detail={data.detail}
                  logo={data.title && data.title.image}
                  skills={data.skills}
                  index={index && index}
                />
              </Stack>
            );
          })}
      </Box>

      {!upLg && (
        <Stack
          className="experience_h"
          direction="row"
          alignItems="center"
          justifyContent="left"
        >
          <Box
            className="expe-left-box"
            sx={(theme) => ({
              borderBottomColor:
                theme.palette.mode === "dark" ? grey[850] : "rgb(2, 138, 104)",
              borderLeftColor:
                theme.palette.mode === "dark" ? grey[850] : "rgb(2, 138, 104)"
            })}
          ></Box>
        </Stack>
      )}
    </Stack>
  );
};

export default Experience;

const styles = {
  d_works_c_h_l: (theme: Theme) => ({
    height: "1px",
    borderRadius: "3px",
    marginLeft: "20px",
    width: "100%",
    backgroundColor:
      theme.palette.mode === "dark" ? grey[850] : theme.palette.grey[900]
  }),
  header: (theme: Theme) => ({
    color: theme.palette.mode === "dark" ? grey[850] : theme.palette.grey[900]
  }),
  rightBox: (theme: Theme) => ({
    borderTopColor:
      theme.palette.mode === "dark" ? grey[850] : "rgb(2, 138, 104)",
    borderRightColor:
      theme.palette.mode === "dark" ? grey[850] : "rgb(2, 138, 104)"
  }),

  experienceContainer: (theme: Theme) => ({
    width: "100%",
    [theme.breakpoints.up("md")]: {
      borderLeftStyle: "solid",
      borderLeftWidth: "0.1px",
      borderLeftColor:
        theme.palette.mode === "dark"
          ? "rgba(99, 115, 129,0.2)"
          : "rgba(235, 239, 242,1)",
      paddingLeft: "40px"
    },
    [theme.breakpoints.down("md")]: {
      borderLeftColor: "transparent"
    },

    paddingLeft: 0
  })
};
