
// import SvgColor from '../../component/svg-color/svg-color';

import { scrollToComponent, scrollToTop } from "../../utils/effects";

// ----------------------------------------------------------------------

interface NavItem {
  title: string;
  handleClick: ()=>void;
  lable: string;
}

const navConfig: NavItem[] = [
  {
    title: 'About',
    handleClick: ()=>{ scrollToTop()},
    lable: "1.0",
  },
  {
    title: 'Experience',
    handleClick: ()=>{ scrollToComponent("expeience")},
    lable: "2.0",
  },



  {
    title: 'Works',
    handleClick: ()=>{scrollToComponent("works")},
    lable:"3.0",
  },

  {
   
    title: 'Contact me',
    handleClick: ()=>{scrollToComponent("contactme")},
    lable:"4.0",
  },

  
 
];

export default navConfig;
