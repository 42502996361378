import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation, AnimationControls } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Box, Theme } from "@mui/material";

interface AnimatedContainerProps {
  path: string;
  link?: string;
}

export const Player: React.FC<AnimatedContainerProps> = ({ path, link }) => {
  const [grow, setGrow] = useState(0.25);

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { clientX, clientY, currentTarget } = event;
    const { left, top, width, height } = currentTarget.getBoundingClientRect();
    const x = clientX - left;
    const y = clientY - top;
    const xPercent = x / width;
    const yPercent = y / height;
    const newGrow = Math.min(0.9, Math.max(0.25, xPercent * yPercent * 2));
    setGrow(newGrow);
  };

  const controls: AnimationControls = useAnimation();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state
  const { ref, inView } = useInView({
    threshold: 0.5, // Adjust the threshold as needed
    triggerOnce: true // Ensures the animation triggers only once
  });

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    // Mute and set volume to a lower level
    videoElement.muted = true;
    videoElement.volume = 0.1; // Reduce the sound volume

    if (inView && !hasAnimated) {
      controls.start({
        opacity: 1,
        scale: 1,
        rotateX: 0,
        rotateY: 0,
        transition: { duration: 0.6 }
      });

      videoElement.currentTime = 1.5; // Start video at 1.5 seconds
      videoElement.play().catch((error) => {});

      setHasAnimated(true);
    }

    const handleVideoEnd = () => {
      videoElement.currentTime = 1.5; // Reset to start at 1.5 seconds
      videoElement.play();
    };

    videoElement.addEventListener("ended", handleVideoEnd);

    return () => {
      videoElement.removeEventListener("ended", handleVideoEnd);
    };
  }, [inView, hasAnimated, controls]);

  const handleClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }

      const videoElement = videoRef.current;

      if (!videoElement) return;

      videoElement.muted = false;
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  const styles = getStyles(grow);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial={{ opacity: 0, scale: 0.9 }}
      style={{ perspective: 1000, zIndex: 10000 }}
      onClick={handleClick} // Toggle play/pause on click
      onMouseMove={handleMouseMove}
    >
      <a
        href={link}
        target="_blank"
        className="mouse_interaction-target"
        rel="noopener noreferrer"
      >
        <Box
          sx={styles.container}
          className="scale-image-parent mouse_interaction-target"
        >
          <video
            ref={videoRef}
            src={path}
            className="scale-image"
            style={styles.videoPlayer}
            controls={false}
            playsInline
          />
          <Box sx={styles.frame} />
        </Box>
      </a>
    </motion.div>
  );
};

const getStyles = (grow: number) => {
  return {
    videoPlayer: {
      top: 0,
      left: 0,
      width: "150%",
      height: "100%",
      objectFit: "cover" as "cover",
      zIndex: 10,
      overflow: "hidden",
      marginTop: "-10px",
      paddingBottom: "-30px"
    },
    container: (theme: Theme) => ({
      position: "relative",
      width: "100%",
      height: "330px",
      [theme.breakpoints.down("xl")]: {
        height: "300px"
      },
      [theme.breakpoints.down("md")]: {
        width: "100%"
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "250px"
      },
      borderRadius: 0.5,

      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // boxShadow: `rgba(17, 12, 46, ${grow > 0.4 ? 0.35 : grow}) 0px 48px 100px 0px`,
      boxShadow:
        "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"
    }),
    frame: (theme: Theme) => ({
      width: "100%",
      height: "10px",
      backgroundColor: "#f9f9f9",
      position: "absolute",
      zIndex: 100,
      bottom: 0
    })
  };
};
