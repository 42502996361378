import { Box, Theme } from "@mui/material";
import React, { useEffect } from "react";
import { mySelf } from "../../data/me"

import { TextSwipe } from "../../utils/effects";
import { Text } from "../../component/Text";
const ContentAppearance = () => {
    useEffect(() => {
        TextSwipe()
    }, [])
    return (
        <div className="contentId_data" >
            <Text
                id="authorIntro"
                className="d_content_brief"
                sx={styles.aboutme}
            >
                {mySelf}
            </Text>
            <Box
                id="contentSwipe"
                className="brief_swip"
                sx={styles.contentSwipe}
            >
                <Text variant="h3">I Build and Design </Text>
                <Box className="slidingVertical" sx={styles.slidingVertical}>
                    <Box id="container" className="swipe-stack-mobile">
                        <span id="text1"></span>
                        <span id="text2"></span>
                    </Box>
                    <svg id="filters">
                        <defs>
                            <filter id="threshold">
                                <feColorMatrix in="SourceGraphic" type="matrix" values="1 0 0 0 0
                  0 1 0 0 0
                  0 0 1 0 0
                  0 0 0 255 -140" />
                            </filter>
                        </defs>
                    </svg>

                </Box>


            </Box>
        </div>
    );
};

export default ContentAppearance;
const styles = {
    aboutme: (theme: Theme) => ({    
      width:'93%',
      color:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey["900"],
      fontSize: 18,
      fontWeight: "500",
      [theme.breakpoints.down("lg")]: {
        fontSize: 20,
    
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 14
      },
    }),
    contentSwipe:(theme: Theme) =>({
        [theme.breakpoints.down('lg')]: {
           flexDirection:'column', 
          
         
          },
    }),
    slidingVertical:(theme: Theme) =>({

        [theme.breakpoints.down('lg')]: {
          marginLeft:0,
           },
    })
  };
  