import { Typography, TypographyProps } from "@mui/material";

interface TextProps extends TypographyProps {
  children: React.ReactNode;
}

export const Text = ({ children,sx, ...props }: TextProps) => {

  return (
    <Typography

      sx={[
        getStyles.label,
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...props}
    >
      {children}
    </Typography>
  );
};

const getStyles = ({
  label: {
    fontFamily: "Clash_Grotesk",
   
  }
});
